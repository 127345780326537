<template>

  <div class="main-container">

    <div class="language-selector-container" v-if="language_selector_shown === true">

      <img class="outlet-collection-logo-small" v-bind:src="outlet_collection_logo" />

      <div class="languages">

        <Language v-for="language in languages" :language="language" :primary_colour="primary_colour"
          v-on:select-language="selectLanguage(language)" :key="language" />

      </div>

      <Footer />

    </div>

    <div v-if="is_loading == true" class="loader">

      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>

    </div>

    <div v-else>

      <div class="outlet-collection-logo-container">

        <img class="outlet-collection-logo" v-bind:src="outlet_collection_logo" />

      </div>

      <div class="language-selector" v-if="languages.length > 1" @click="showLanguageSelector()">

        <div>

          <img src="@/assets/icon_language.svg" />

        </div>

      </div>

      <div class="category-search-bar" v-if="hide_search_and_categories_bar == false">

        <div id="category-selector">
          <span @click="selectCategory('All categories'), toggleDropdown()"
            :class="{ 'selected-category' : 'All categories' === selected_category } "
            :style="backgroundStyle('All categories')"><img
              :class="{ 'not-selected' : selected_category != 'All categories' }"
              src="@/assets/all_categories.svg" /><img
              :class=" { 'flipped' : show_category_dropdown == true, 'not-selected' : selected_category != 'All categories' } "
              src="@/assets/dropdown-v.svg" /> </span>
          <div v-if="show_category_dropdown == true">
            <span v-for="category in filteredCategories" v-bind:key="category.id"
              :style="backgroundStyle(getName(category))"
              :class="{ 'selected-category' : getName(category) === selected_category } "
              @click="selectCategory(getName(category))">
              {{ getName(category) }}
            </span>
          </div>
        </div>

        <div id="search-bar">
          <input id="search-input" placeholder="Search" v-model="search_phrase">
          <img src="@/assets/search-icon.svg" />
        </div>

      </div>

      <draggable class="outlets-container" handle=".handle" v-bind="dragOptions" v-model="outlets" @start="drag=true"
        @end="onDragEnd(outlets)" v-bind:class="{ 'flex-column': full_width_outlet_names == true }">

        <div :value="outlet.id" v-for="outlet in filteredOutlets" :sort="outlet.sort" :key="outlet.id"
          :class="{ 'category-header': outlet.category_header, 'outlet': outlet.category_header == false, 'hidden' : (outlet.hidden == true && signed_in == false), 'hidden-category' : selected_category != outlet.category && selected_category != 'All categories', 'full-width-outlet-container': full_width_outlet_names == true, 'image-container' : menu_names_only == false && outlet.category_header == false}"
          :style="{'color':primary_colour}">

          <div class="buttons">

            <div v-if="canEdit()" class="handle">
              <div></div>
              <div></div>
              <div></div>
            </div>

            <img @click="toggleHidden(outlet)" v-if="canEdit() && outlet.hidden == true"
              class="toggle hidden hidden-button" src="@/assets/hidden.svg" />

            <img @click="toggleHidden(outlet)" v-if="canEdit() && outlet.hidden == false" class="toggle visible"
              src="@/assets/visible.svg" />

            <img v-if="user_type == 'admin' || canEdit()" @click="editOutlet(outlet)" class="edit-button"
              src="@/assets/edit_icon.svg" />

            <img v-if="user_type == 'admin' || canEdit()" @click="deleteOutlet(outlet)" class="delete-button"
              src="@/assets/garbage_icon.svg" />

          </div>

          <div v-if="outlet.category_header == true && shown_categories.includes(outlet.category)"
            v-bind:class="getClass(outlet)">

            <h2
              @click="redirectToMenu(outlet.category_header, outlet.menu_id, getName(outlet), outlet.link_only_outlet, outlet.link_only_outlet_link)"
              :class="{ 'center-text' :full_width_outlet_names == true }">{{ getName(outlet) }}<img
                v-if="collapse_category_headers == true"
                :class="{ 'flipped' : uncollapsed_categories.includes(outlet.category) }"
                src="@/assets/dropdown-v_black.svg" /></h2>

          </div>

          <div v-else v-bind:class="getClass(outlet)">

            <div v-if="collapse_category_headers === true">

              <div v-if="uncollapsed_categories.includes(outlet.category) || outlet.category == ''">

                <img v-if="outlet.logo != null && menu_names_only == false" v-bind:class="getClass(outlet)"
                  @click="redirectToMenu(outlet.category_header, outlet.menu_id, getName(outlet), outlet.link_only_outlet, outlet.link_only_outlet_link)"
                  v-bind:src="outlet.logo" />

                <h3 v-else
                  @click="redirectToMenu(outlet.category_header, outlet.menu_id, getName(outlet), outlet.link_only_outlet, outlet.link_only_outlet_link)"
                  :style="{'background-color':primary_colour}"
                  v-bind:class="{ 'full-width' : full_width_outlet_names == true && outlet.category_header == false }">
                  {{ getName(outlet) }}</h3>

              </div>

            </div>

            <div v-else>

              <div v-if="shown_categories.includes(outlet.category) || outlet.category == ''">
                
                <img v-if="outlet.logo != null && menu_names_only == false" v-bind:class="getClass(outlet)"
                  @click="redirectToMenu(outlet.category_header, outlet.menu_id, outlet.link_only_outlet, outlet.link_only_outlet_link, region)"
                  v-bind:src="outlet.logo" />

                <h3 v-else
                  @click="redirectToMenu(outlet.category_header, outlet.menu_id, getName(outlet), outlet.link_only_outlet, outlet.link_only_outlet_link)"
                  :style="{'background-color':primary_colour}"
                  v-bind:class="{ 'full-width' : full_width_outlet_names == true && outlet.category_header == false }">
                  {{ getName(outlet) }}</h3>

              </div>

            </div>

          </div>

        </div>

      </draggable>

      <CustomLinks v-if="tripadvisor_link != '' && tripadvisor_link != null" :tripadvisor_link="tripadvisor_link" />

      <div v-if="user_type == 'admin' || user_id == admin_1 || user_id == admin_2 || user_id == admin_3"
        class="admin-options">

        <router-link class="add-outlet_button" to="/add_outlet"> Add an outlet</router-link>

      </div>

    </div>

  </div>

</template>





<script>
import draggable from 'vuedraggable';
import Language from '../components/Language';
import CustomLinks from '../components/CustomLinks';
import Footer from '../components/Footer';
import { EventBus } from '../../event-bus.js';

export default {
  name: 'Main',
  components: {
      draggable,
      Language,
      CustomLinks,
      Footer
      // CategorySelector
  },
  data () {
      return {
          user_type: '',
          outlets: [],
          languages: [],
          selected_language: '',
          signed_in: false,
          user_id: 0,
          logos: [],
          categories: [],
          outlet_collection_name: '',
          outlet_collection: [],
          hidden_items: [],
          url: process.env.VUE_APP_API_URL,
          outlet_collection_logo: '',
          selected_category: 'All categories',
          show_category_dropdown: false,
          full_width_outlet_names: Boolean,
          search_phrase: '',
          primary_colour: '',
          secondary_colour: '',
          hide_search_and_categories_bar: Boolean,
          collapse_category_headers: Boolean,
          shown_categories: [],
          uncollapsed_categories: [],
          all_categories_text: '',
          language_selector_shown: false,
          tripadvisor_link: '',
          admin_1: 0,
          admin_2: 0,
          admin_3: 0,
          menu_names_only: false,
          is_loading: true,
          table_number: '',
          region: ''
      }
  },
  created() {
      if (localStorage.signedIn) {
          this.$http.secured.get('/app_current_user')
              .then(response => { this.user_type = response.data.user_type, this.user_id = response.data.user, this.signed_in = true })
              .catch(error => this.setError(error, 'Something went wrong'))
      }
      this.getOutlets()
      this.checkForTableNumber();
  },
  mounted() {
      EventBus.$on('user_signed_out', signedIn => {
          console.log('User signed out')
          this.user_id = 0
          this.user_type = ''
          this.signed_in = false 
      });
  },
  methods: {
      setError (error, text) {
          this.error = (error.response && error.response.data && error.response.data.error) || text
      },
      checkForTableNumber() {
          if (new URL(location.href).searchParams.get('table') != null) {
              localStorage.setItem('table_number', new URL(location.href).searchParams.get('table'))
              this.table_number = new URL(location.href).searchParams.get('table')
          }
      },
      getOutlets() {
          let custom_url = window.location.hostname.split('.').shift();
          this.$http.plain.get('/app_get_outlets/' + custom_url)
            .then(response => { 
              console.log(`REGION: ${response.data.outlet_collection.region}`)
              this.outlet_collection = response.data.outlet_collection
              this.languages = response.data.languages
              this.outlet_collection_name = response.data.outlet_collection.name
              this.full_width_outlet_names = response.data.outlet_collection.outlet_names_full_width
              this.categories = response.data.categories
              this.logos = response.data.logos
              this.outlet_collection_logo = response.data.outlet_collection.logo.url
              this.tripadvisor_link = response.data.outlet_collection.tripadvisor_link
              this.outlets = response.data.outlets
              this.primary_colour = response.data.outlet_collection.primary_colour
              this.secondary_colour = response.data.outlet_collection.secondary_colour
              this.admin_1 = response.data.outlet_collection.admin_1
              this.admin_2 = response.data.outlet_collection.admin_2
              this.admin_3 = response.data.outlet_collection.admin_3
              this.menu_names_only = response.data.outlet_collection.use_menu_names_only
              this.hide_search_and_categories_bar = response.data.outlet_collection.hide_search_and_categories_bar
              this.collapse_category_headers = response.data.outlet_collection.collapse_categories,
              this.region = response.data.outlet_collection.region
              this.getLanguage()
              this.getCategories()
              this.is_loading = false
              })
          .catch(error => this.setError(error, 'Something went wrong'))            
      },
      redirectToMenu(category_header, menu_id, name, link_only_outlet, link_only_outlet_link) {
        if (category_header == false) {
          const selectedRegion = this.region === 'asia' ? 'asia' : 'menus'
          console.log(`selectedRegion: ${selectedRegion}`)
          if (link_only_outlet != true) {   
            if (localStorage.table_number != undefined || this.table_number != '') {
                var tableString = this.table_number || localStorage.table_number
              if (this.outlet_collection.use_menu_builder_v2_for_menus === true) {
                console.log('block 1 firing');
                    window.location = `https://${selectedRegion}.menulingua.com/${menu_id}/${this.selected_language}?table=${tableString}`
              } else {
                console.log('block 2 firing');
                    window.location = this.url + "/menus/" + menu_id + "/view_mobile/" + this.selected_language + `?table=${tableString}`
                }
            } else {
              if (this.outlet_collection.use_menu_builder_v2_for_menus === true) {
                console.log('block 3 firing');
                  window.location = `https://${selectedRegion}.menulingua.com/${menu_id}/${this.selected_language}`
              } else {     
                console.log('block 4 firing');            
                    window.location = this.url + "/menus/" + menu_id + "/view_mobile/" + this.selected_language
                }
            }
          } else {
            window.location.replace(this.prependHttpIfMissing(link_only_outlet_link))
          }
        } else {
          if (this.collapse_category_headers == true) {
              console.log(name)
              if (this.uncollapsed_categories.includes(name)) {
                  console.log('this is already in the array')
                  var index = this.uncollapsed_categories.indexOf(name)
                  console.log(index)
                  this.uncollapsed_categories.splice(index, 1)
              } else {
                  this.uncollapsed_categories.push(name)
              }
          }
        }
      },
      prependHttpIfMissing(inputString) {
        // Check if the inputString starts with "http://"
        if (!inputString.startsWith("http://") && !inputString.startsWith("https://")) {
          // If it doesn't, prepend "http://"
          return "https://" + inputString;
        }
        // If it already starts with "http://", return the inputString as is
        return inputString;
      },
      onDragEnd(outlets) {
          for (let i = 0; i < this.outlets.length; i++) {
              outlets[i].sort = i + 1
              this.$http.secured.post('/app_update_outlet_sort/' + outlets[i].id + '/' + outlets[i].sort)
                  .then(response => { 'THIS WAS SORTED' })
                  .catch(error => this.setError(error, 'Something went wrong'))
          }
      },
      canEdit() {
          if (this.user_type == 'admin' || this.user_id == this.outlet_collection.admin_1 || this.user_id == this.outlet_collection.admin_2 || this.user_id == this.outlet_collection.admin_3) {
              return true
          } else {
              return false
          }
      },
      showLanguageSelector() {
          this.language_selector_shown = true;
      },
      getLanguage() {
          let languages = this.languages;
          if (this.$router.currentRoute.params.language != null) {
              if (languages.includes(this.$router.currentRoute.params.language)) {
                  this.selected_language = this.$router.currentRoute.params.language
              } else {
                  if (languages.length == 1) {
                      this.selected_language = languages[0]
                      this.$router.push({ path: `/${this.selected_language}` })
                  } else {
                      this.language_selector_shown = true;
                  }                    
              }
          } else {
              if (languages.length == 1) {
                  this.selected_language = languages[0]
                  this.$router.push({ path: `/${this.selected_language}` })
              } else {
                  this.language_selector_shown = true;
              }
          }
      },
      selectLanguage(language) {
          this.selected_language = language;
          this.$router.push({ path: `/${language}` })
          this.language_selector_shown = false;
          this.getOutlets()
          this.selectCategory('All categories')
      },
      getName(outlet) {
          let language = this.selected_language
          return (outlet[language + '_name'])
      },
      getCategories() {
          let category_name = ''
          this.outlets.forEach((outlet) => {
              if (outlet.category_header == true) {
                  category_name = this.getName(outlet)
                  if (this.collapse_category_headers == false || this.canEdit() === true) {
                      this.shown_categories.push(category_name)
                  }
                  if (this.collapse_category_headers == true) {
                      this.shown_categories.push(category_name)
                  }
              }
              outlet.category = category_name
          })
      },
      getPrimaryColour() {
          return this.primary_colour
      },
      getSecondaryColour() {
          return this.secondary_colour
      },
      toggleHidden(outlet) {
          this.$http.secured.post('/app_toggle_hidden_outlet/' + outlet.id)
              .then(response => { 
                  if (outlet.hidden == false) {
                      outlet.hidden = true
                  } else {
                      outlet.hidden = false
                  }
              })
              .catch(error => this.setError(error, 'Something went wrong'))
      },
      editOutlet(outlet) {
          this.$router.push({ name: 'edit_outlet', params: { id: outlet.id }})
      },
      deleteOutlet(outlet) {
         this.$http.secured.delete('/outlet/' + outlet.id + '/destroy') 
          .then(response => { 
              this.outlets = this.outlets.filter((e)=>e.id !== outlet.id )
          })
          .catch(error => this.setError(error, 'Something went wrong'))
      },
      getClass(outlet) {
          if (this.canEdit()) {
              return { 'hidden-outlet' : outlet['hidden'] }
          } else {
              return { 'invisible-outlet' : outlet['hidden'] }
          }
      },
      selectCategory(name) {
          this.shown_categories = []
          this.selected_category = name
          this.shown_categories.push(name)
          if (name === 'All categories') {
              if (this.collapse_category_headers === true) {
                  this.uncollapsed_categories = []
              } else {
                  this.shown_categories = []
                  this.categories.forEach((category) => {
                      this.shown_categories.push(this.getName(category))
                  })
              }
          } else {
              this.uncollapsed_categories.push(name)
          }
      },
      toggleDropdown() {
          console.log('I clicked on this button')
          if (this.show_category_dropdown == false) {
              this.show_category_dropdown = true
          } else {
              this.show_category_dropdown = false
          }
      },
      backgroundStyle(category_name) {
          if (category_name == this.selected_category) {
              return {
                  'background-color':this.primary_colour
              }
          } else {
              return 'background-color:white;color:black;'
          }
      }       
  },
  computed: {    
      dragOptions() {
          return {
              animation: 100,
              group: "description",
              disabled: false,
              ghostClass: "ghost"
          };
      },
      filteredCategories () {
          return this.categories.filter(category => category.hidden === false);
      },
      filteredOutlets () {
          if (this.search_phrase) { 
              return this.outlets.filter((item)=>{
              return this.search_phrase.toLowerCase().split(' ').every(v => this.getName(item).toLowerCase().includes(v))
              })
          } else {
              return this.outlets
          }
      }
  }
}

</script>


<style scoped>

h1 {
  font-weight: 400;
}

.main-container {
  max-width: 1200px;
  margin: auto;
  width: 90%;
}

.outlet-collection-logo-container {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 5vh;
  justify-content: center;
}

.outlet-collection-logo {
  max-width: 500px;
  /* width: 90%; */
  margin: auto;
  max-height: 175px;
}

.outlet-collection-logo-small {
  max-width: 250px;
  max-height: 100px;
  margin: 25px 0px 25px 0px;
  /* margin: auto; */
}

.admin-options {
  width: 100%;
  margin-top: 100px;
  display: flex;
  justify-content: center;
}

.admin-options a {
  border: 1px solid lightgray;
  color: gray;
  box-sizing: border-box;
  padding: 10px;
  font-size: 12.5px;
  border-radius: 10px;
}

.admin-options a:hover {
  background-color: lightgray;
  color: black;
  transition: 0.2s;
}

.outlets-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.outlets-container div {
  /* width: auto; */
  /* width: 45%; */
  /* width: 100% !important; */
  /* border: 1px dotted lightgray; */
}

.full-width {
  /* width: 90%; */
  max-width: 400px;
  width: 400px;
  font-size: 15px !important;
  /* width: 400px !important; */
}

.flex-column {
  flex-direction: column;
  align-items: center;
}

.category-header {
  width: 100% !important;
  box-sizing: border-box;
  /* margin: 0px 10px 0px 10px; */
  font-weight: 600;
  /* color: #057f8e; */
  position: relative;
  

}

.category-header h2 {
  font-weight: 600;
  margin: 30px 0px 10px 0px;
  background-color: white !important;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  /* border-bottom: 1px solid #057f8e; */
}

.category-header h2 img {
  width: 20px;
  height: 20px;
  margin-left: 20px;
}

.outlet {
  /* margin: 20px 0px 20px 0px; */
  position: relative;
  /* display: flex; */
  width: 47.5%;
  /* max-width: 30%; */
  /* border: 1px dotted lightgray; */
}

.outlet div {
width: 100%;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 10px 20px 10px 20px; */
  /* width: 150px; */
  /* max-width: 150px; */
}

.image-container div div img {
  margin: 10px 20px 10px 20px;
  /* max-width: 150px; */
}

.buttons {
  display: flex;
  /* justify-content: center; */
  align-self: flex-start;
  position: relative;
  /* border: 1px dotted lightgray; */
  max-width: 100px;
}

.outlet img {
  max-width: 150px;
  max-height: 100px;
  /* align-self: center; */
  /* margin: 10px 20px 10px 20px; */
  /* margin: auto; */
  /* position: relative; */
  cursor: pointer;
  /* margin: 0px 20px 0px 20px; */
}

.hidden-outlet {
  opacity: 0.5 !important;
}

.invisible-outlet {
  display: none;
  width: 0px !important;
}

.hidden-outlet-prod {
  display: none !important;
}

.outlet img:hover {
  opacity: 0.7;
  transition: 0.5s;
}

.outlet h3 {
  font-size: 14px;
  font-weight: 600;
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  box-sizing: border-box;
  background-color: #88b83f;
  color: white;
  height: 50px;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  position: relative;
  /* margin: 10px 10px 10px 10px; */
  min-width: 150px;
  width: 100% !important;
}

.outlet h2:hover {
  opacity: 0.7;
  transition: 0.5s;
}

.ghost {
opacity: 0.5;
background: #c8ebfb;
}

.handle {
  background-color: white;
  border: 1px solid lightgray;
  width: 25px !important;
  height: 25px !important;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 8px;
  position: absolute;
  z-index: 2;
}

.handle div {
  width: 15px !important;
  height: 1px !important;
  background-color: lightgray;
}

.toggle {
  width: 25px;
  height: 25px;
  position: absolute;
  left: 30px;
  display: flex;
  box-sizing: border-box;
  padding: 5px;
  border-radius: 50%;
  z-index: 2; 
}

.center-text {
  justify-content: center !important;
  text-align: center;
}

.edit-button {
  width: 25px;
  height: 25px;
  position: absolute;
  left: 60px;
  display: flex;
  box-sizing: border-box;
  padding: 5px;
  border-radius: 50%;
  z-index: 2; 
  background-color: #F1F1F2;
}

.delete-button {
  width: 25px;
  height: 25px;
  position: absolute;
  left: 90px;
  display: flex;
  box-sizing: border-box;
  padding: 5px;
  border-radius: 50%;
  z-index: 2; 
  background-color: #A33B3B;
}

.visible {
  background-color: #1b75bb;
}

.hidden {
  background-color: lightgray;
  display: none;
}

.hidden-button {
  display: flex;
}

.category-search-bar {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  border-radius: 10px;
  box-sizing: border-box;
}

#category-selector {
  display: flex;
  width: 50%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

#category-selector div {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
}

#category-selector span {
  width: 100%;
  cursor: pointer;
  display: flex;
  box-sizing: border-box;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
  font-size: 12.5px;
  font-weight: 600;
  height: 42px;
  border-radius: 10px;
}

#category-selector span:first-child {
  border-radius: 10px 0px 0px 10px;
}


#category-selector div span:first-child {
  border-radius: 10px 10px 10px 10px;
}

#category-selector span img {
  width: 15px;
  height: 15px;
  box-sizing: border-box;
  padding: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover;
}

.selected-category {
  color: white !important;
  border: 0px !important;
  height: 42px;
}

.flipped {
  transform: rotateX(180deg);
  transition: 0.2s;
}

.hidden-category {
  display: none;
}

#search-bar {
  width: 49%;
  display: flex;
  flex-direction: row;
}

.not-selected {
  filter: invert(83%) sepia(36%) saturate(0%) hue-rotate(194deg) brightness(92%) contrast(87%);
  transition: 2s;
}

#search-bar img {
  width: 20px;
  height: 20px;
  margin-top: 10px;
  margin-right: 5px;

}

input {
  border-bottom: 1px solid lightgray;
  width: 100%;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  font-size: 15px;
  height: 40px;
  border: 0px;
  font-family: 'Montserrat', sans-serif;
}

.loader {
  height: 100vh;
}

.full-width-outlet-container {
  display: flex;
  justify-content: center;
}

.language-selector {
  width: 100%;
  display: flex;
  height: 30px;
  justify-content: center;
  margin-bottom: 20px;
}

.language-selector div {
  padding: 5px;
  border-radius: 50%;
  /* box-shadow: 0 0 8px rgba(0, 0, 0, 0.1); */
  background-color: white;
  box-sizing: border-box;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.language-selector div:hover {
  opacity: 0.7;
  transition: 0.2s;
}

.language-selector div img {
  height: 15px;
  width: 15px;
}

.language-selector-container {
  width: 100%;
  margin: auto;
  min-height: 100vh;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: white;
  opacity: 1;
  position: fixed;
  z-index: 10;
  justify-content: space-between;
  align-items: center;
}

.languages {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width: 500px;
  margin: auto;
  align-items: center;
}


@media(max-width: 600px) {

  .full-width-outlet-container {
      width: 100%;
      max-width: 250px;
  }

  .image-container div div img {
      max-height: 60px;
      max-width: 60px;
  }

  .full-width {
      /* width: 250px !important; */
      margin: auto;
      /* max-width: 250px; */
      width: 250px !important;
      /* max-width: 250px !important; */
  }

  .category-search-bar {
      flex-direction: column;
      box-shadow: 0 0 0;
  }

  #search-bar {
      width: 100%;
      box-sizing: border-box;
      padding: 5px;
      border: 1px solid lightgray;
      height: 42px;
      border-radius: 10px;
      align-items: center;
  }
 
  #category-selector {
      width: 100%;
      margin-bottom: 10px;
      /* border: 1px solid lightgray; */
      border-radius: 10px;
  }

  #category-selector div {
      padding: 0px;
      /* box-shadow: 0 0 8px rgba(0, 0, 0, 0.1); */
      /* border-bottom: 1px solid lightgray; */
  }

  #category-selector span:first-child {
      border-radius: 10px 10px 10px 10px;
  }

  input {
      height: auto;
  }

  #search-bar img {
      margin: 0px 0px 0px 0px;
  }

  .outlet img {
      max-height: 75px;
      max-width: 75px;
      /* max-width: 95%;
      max-height: 80%; */
  }
  
  .outlet h3 {
      font-size: 10px;
      min-width: 0px;
      /* width: 75px; */
      /* width: 30%; */
      /* min-width: 90px; */
      /* max-width: 100 */
      /* max-width: 50%; */
      /* width: 30% !important; */
  }

  /* .image-container h3 {
      width: 75px !important;
  } */

  .outlet-collection-logo-container {
      
  }

  .outlet-collection-logo {
      width: 90%;
      object-fit: contain;

      margin-top: 20px;
  }

}

@media(max-width: 500px) {
  .outlet h3 {
      font-size: 10px;
  }
}

</style>

