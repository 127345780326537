<template>
  <div id="app">
    <Header :key="headerKey" />
    <router-view class="content-box" />
    <Footer />
  </div>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'
import { EventBus } from '../event-bus.js';

export default {
  name: 'App',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      headerKey: 0
    }
  },
  mounted() {
    EventBus.$on('user_signed_in', signedIn => {
      this.headerKey += 1;
    });
  }
}
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;600;800&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
}

a {
  text-decoration: none;
  color: black;
}

.back-button {
  /* border: 1px solid lightgray; */
  color: gray;
  box-sizing: border-box;
  /* padding: 10px; */
  font-size: 12.5px;
  border-radius: 10px;
  display: inline-flex;
}

/* loaders */
.loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: black;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}



</style>
