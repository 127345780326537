<template>

<div id="login-wrapper">

        <div>

            <h2>Sign in</h2>

            <div v-if="is_loading === true" class="loader">
                <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
            
            <form @submit.prevent="signin" v-else>
                <div class="error-message" v-if="error">{{ error }} </div>

                <div>
                    <label for="email">Email address</label>
                    <input type="email" v-model="email" id="email" placeholder="Enter email">
                </div>

                <div>
                    <label for="password">Password</label>
                    <input type="password" v-model="password" id="password" placeholder="Enter password">
                </div>

                <button type="submit">Login</button>

            </form>

        </div>

    </div>

</template>

<script>
import { EventBus } from '../../event-bus.js';

export default {
    name: 'Login',
    data () {
        return {
            email: '',
            password: '',
            is_loading: true,
            error: ''
        }
    },
    created () {
        this.checkForSession()
        this.checkSignedIn()
        this.is_loading = false
        // console.log(document.cookie)
    },
    updated () {
        this.checkSignedIn()
    },
    methods: {
        signin (e) {
            if (this.email == '') {
                this.error = 'Invalid email or password'
                e.preventDefault();
            } else if (this.password == '')  {
                this.error = 'Invalid email or password'
                e.preventDefault();
            } else {
                this.is_loading = true
                this.$http.plain.post('/login', { email: this.email, password: this.password })
                .then(response => this.signinSuccessful(response))
                .catch(error => this.signinFailed(error))
            }
        },
        signinSuccessful (response) {
            localStorage.token = response.data.access
            localStorage.csrf = response.data.csrf
            localStorage.signedIn = true
            this.emitSignedIn()
            this.error = ''
            this.$router.replace('/')
        },
        emitSignedIn() {
            EventBus.$emit('user_signed_in', this.signedIn);
        },
        signinFailed (error) {
            // this.error = (error.response && error.response.data && error.response.data.error) || ''
            // console.log(error)
            this.error = 'Invalid email or password'
            delete localStorage.token
            delete localStorage.csrf
            delete localStorage.signedIn
            this.is_loading = false
        },
        checkForSession() {
            this.$http.plain.post('/check_for_login')
            .then(response => {console.log(response),
                localStorage.token = response.data.access,
                localStorage.csrf = response.data.csrf,
                this.checkSignedIn() })
            .catch(error => 'Didnt work')
        },
        checkSignedIn() {
            // if (localStorage.token == "undefined" || localStorage.csrf == "undefined") {
            if (localStorage.signedIn) {
                this.emitSignedIn()
                this.$router.replace('/orders')
            }
        },
        getCookie(name) {
        let cookie = {};
        document.cookie.split(';').forEach(function(el) {
            let [k,v] = el.split('=');
            cookie[k.trim()] = v;
        })
            return cookie[name];
            // console.log(cookie[name])
        }
    }
}


</script>

<style scoped>

    #login-wrapper {
        max-width: 400px;
        width: 90%;
        margin: auto;
        background-color: white;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 30px;
        border: 1px dotted lightgray;
        border-radius: 10px;
        margin-top: 10vh;
    }


    #login-wrapper h2 {
        font-weight: 600;
        margin: 0px 0px 40px 0px;
    }

    form {
        display: flex;
        flex-direction: column;
    }

    input {
        width: 100%;
        border-bottom: 1px solid lightgray;
        border-top: 0px;
        border-right: 0px;
        border-left: 0px;
        font-size: 15px;
        height: 40px;
        margin: 10px 0px 50px 0px;
        font-family: 'Montserrat', sans-serif;
    }

    label {
        font-size: 15px;
        font-weight: 600;
        margin: 20px 0px 10px 0px;
    }

    button {
        background-image: linear-gradient(to right, rgb(153, 202, 60), rgb(7, 153, 72));
        display: flex;
        color: white;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        justify-content: center;
        border: 0px;
        height: 40px;
        align-items: center;
        border-radius: 5px;
    }

    .error-message {
        color: red;
        font-size: 12.5px;
        font-weight: 600;
        box-sizing: border-box;
        padding: 5px;
        border: 1px solid red;
        border-radius: 2.5px;
        margin: 0px 0px 20px 0px;
        align-self: flex-start;
    }

    .loader {
        height: 284px;
    }


</style>