

import axios from 'axios';

// const API_URL = "https://shielded-brushlands-26226.herokuapp.com"

// const API_URL = "http://localhost:3000"

const API_URL = process.env.VUE_APP_API_URL
// const API_URL = "https://menulingua.com"

// const API_URL = process.env.VUE_APP_API_URL

// axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'https://menulingua-order-dashboard.onrender.com'

const securedAxiosInstance = axios.create({
    baseURL: API_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': 'https://menulingua-order-dashboard.onrender.com',
        'Authorization': 'Bearer ' + localStorage.token
    }
})

const plainAxiosInstance = axios.create({
    baseURL: API_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
        // 'Access-Control-Allow-Origin': 'https://menulingua-order-dashboard.onrender.com'
        // 'Authorization': 'Bearer ' + localStorage.token
    }   
})


securedAxiosInstance.interceptors.request.use(config => {
    
    // const method = config.method.toUpperCase();

        config.headers = {
            ...config.headers,
            'Authorization': 'Bearer ' + localStorage.token,
            'X-CSRF-TOKEN': localStorage.csrf
        }


    return config
});

securedAxiosInstance.interceptors.response.use(null, error => {
    if (error.response && error.response.config && error.response.status === 401) {
            return plainAxiosInstance.post('/refresh', {}, { headers: {
                'Authorization': 'Bearer ' + localStorage.token,
                'X-CSRF-TOKEN': localStorage.csrf,
            } 
        })
        .then(response => {
            localStorage.token = response.data.access
            localStorage.csrf = response.data.csrf
            localStorage.signedIn = true

            let retryConfig = error.response.config
            retryConfig.headers['X-CSRF-TOKEN'] = localStorage.csrf
            // retryConfig.headers = { 'Authorization': 'Bearer ' + localStorage.token }
            return plainAxiosInstance.request(retryConfig)
        }).catch(error => {
            console.log(error)
            console.log('there is an error')
            delete localStorage.token
            delete localStorage.csrf
            delete localStorage.signedIn
            location.replace('/')
            return Promise.reject(error)
        })
    } else {
        return Promise.reject(error)
    }
})

export { securedAxiosInstance, plainAxiosInstance }