<template>

    <div class="navbar" v-if="signedIn()">

        <a class="navbar-link" href="#" @click.prevent="signOut">Sign out</a>

    </div>


</template>

<script>
import { EventBus } from '../../event-bus.js';

export default {
    name: 'Header',
    data () {
        return {
            email: ''
        }
    },
    created () {
        this.signedIn()
    },
    methods: {
        setError (error, text) {
            this.error = (error.response && error.response.data && error.response.data.error) || text
        },
        signedIn () {
            return localStorage.signedIn
        },
        emitSignedOut() {
            EventBus.$emit('user_signed_out', this.signedIn);
        },
        signOut () {
        this.$http.secured.delete('/app_session_delete')
            .then(response => {
            delete localStorage.token
            delete localStorage.csrf
            delete localStorage.signedIn
            this.$forceUpdate()
            this.emitSignedOut()
            })
            .catch(error => this.setError(error, 'Cannot sign out'))
        }
    }
}

</script>

<style scoped>

.navbar {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid lightgray;
    box-sizing: border-box;
    padding: 10px;
}

.navbar-link {
    font-size: 12.5px;
    font-weight: 600;
}




</style>