import Vue from 'vue'
import App from './App.vue'
import Main from './components/Main.vue'
import Login from './components/Login.vue'
import AddOutlet from './components/AddOutlet.vue'
import VueAxios from 'vue-axios'
import VueRouter from 'vue-router'
import EditOutlet from './components/EditOutlet.vue'
// import { EventBus } from './event-bus.js'
import { plainAxiosInstance, securedAxiosInstance } from './backend/axios'




Vue.use(VueRouter);

Vue.use(VueAxios, {
  secured: securedAxiosInstance,
  plain: plainAxiosInstance
})

export const router = new VueRouter({
  mode: 'history',
  routes: [
      { path: "/", component: Main },
      { path: "/login", component: Login },
      { path: "/add_outlet", component: AddOutlet },
      { path: "/edit_outlet/:id", name: "edit_outlet", component: EditOutlet },
      { path: "/:language", component: Main },
  ]
});

Vue.config.productionTip = false

new Vue({
  el: '#app',
  securedAxiosInstance,
  plainAxiosInstance,
  router,
  components: { App },
  template: '<App/>'
})
