<template>

    <a :href="getLink()" target="_blank">

        <img src="@/assets/tripadvisor_button_transparent.png" />

    </a>



</template>


<script>

export default {
    name: 'CustomLinks',
    props: ['tripadvisor_link'],
    data () {
        return {

        }
    },
    methods: {
        getLink() {
            return this.tripadvisor_link
        }
    }
}


</script>

<style scoped>

    a {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 5vh;
    }
    
    a img {
        width: 150px;
        height: auto;
    }


</style>