<template>

  <AddOutlet :editing="true" />

</template>



<script>
import AddOutlet from '@/components/AddOutlet'

export default {
  name: 'EditOutlet',
  components: {
    AddOutlet
  }
}


</script>
